import React from 'react';
import "./test.css"

export default function Test(){
  return(
    <div>
        <div className="dot" />
        <div className="ntnu"> NTNU </div>
        <div className="rightbottom"/>
        <div className="vertline"/>
        <div className="right"/>
        <div className="rightvertline" />
    </div>
  )
}